import React from 'react'
import Link from 'gatsby-link'
import {thankyou_title, thankyou_text} from '../components/strings'
import Logo from '../components/Header/logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Layout from '../components/layout'

const ThankYou = () => (
    <Layout>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' className="hero is-fullheight thankyou reserve">
  <Link to="/" className="close-btn"><FontAwesomeIcon icon="times"/></Link>
         <div className="hero-body">
              <div className="container">
              
                  <h2 className="">{thankyou_title}</h2>
                  <p className="">{thankyou_text}</p> 
                  <div className="logo"><Logo/></div>  
              </div>
        
      </div>
   </ScrollAnimation>
   </Layout>
)

export default ThankYou
